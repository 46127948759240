

.settings-pref-designer{
    margin: 25px;
    border: 1px solid gainsboro;
     /* border: 10px solid yellow; */
}

.settings-pref-designer .designer-controls {
    padding: 0px 50px 30px 50px;
}

.settings-pref-designer input[type="number"]{
    color: #555;
    height: 40px;
    width: 40%;
    font-size: 16px;
    padding-left: 17px;
}

.settings-pref-designer input[type="text"],
.settings-pref-designer input[type="url"]{
    color: #555;
    height: 40px;
    width: 40%;
    font-size: 16px;
    padding-left: 16px;
}

.settings-pref-designer .form-input i.input-left-icon
{  
  color: #A9A9A9; 
  position: absolute;
  float:left;
  z-index: 2;
  margin-left: 15;
  transform: scale(1.4, 1.4);
  text-align: center;
  font-style: normal;
  padding-top: 8px;
  margin-left: 5px;
  margin-right: 10px;
}


.settings-pref-designer .labelDesc
{
  color : #A9A9A9;
}

