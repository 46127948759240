.cloud-printer-tabs
{
  margin-top: 16px;
}

.nav-link
{
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.nav > li > a:hover, .nav > li > a:focus 
{
  text-decoration: none;
  background-color: #eeeeee;
}

