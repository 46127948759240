.form-container {
    background-color: #ffffff;
}

.form-container > form {
    background-color: lightgray;
    width: 50%;
    padding: 5%;
    display: inline-block;
    text-align: left;
    height: 100%;
}


