.FormTextBoxDemo .c1 {
  margin: 10px;
  background-color: white;
  border: none;
  padding: 10px;
  text-align: left;
}


.AmountInputBox input[type="text"] {
    color: #555;
    height: 45px;
    line-height: 50px;
    width: 100%;
    font-size: 20px;
    padding-left: 20px;
}

.AmountInputBox
i.input-left-icon
{
  color: #555; 
  position: absolute;
  display: block;
  top: 43%;
  transform: scale(1.5, 1.5);
  pointer-events: none;
  width: 20px;
  text-align: center;
  font-style: normal;
}