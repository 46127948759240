/* Info found on https://www.w3schools.com/howto/howto_js_fullscreen_overlay.asp */
/* The Overlay (background) */
.do_dialog_overlay
{
  display: block;

  /* Height & width depends on how you want to reveal the overlay (see JS below) */    
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 20; /* legacy menu currenlty has a z-inedx of 16... we need to make sure this one is higher*/
  left: 0;
  top: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.do_dialog_box
{
  display: block;

  /* Height & width depends on how you want to reveal the overlay (see JS below) */    
  height: 30%;
  width: 50%;
  position: fixed; /* Stay in place */
  z-index: 20; /* legacy menu currenlty has a z-inedx of 16... we need to make sure this one is higher*/
  left: 25%;
  top: 25%;

  color: rgb(69 150 202);
  background-color: white;

  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  border-radius: 25px;
  border: solid;
  border-color: rgb(69 150 202);
  border-width: 7px;
}


/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.do_dialog_overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #ffffff;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.do_dialog_overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.do_dialog_overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .do_dialog_overlay a {font-size: 20px}
  .do_dialog_overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}


.do_content_text
{
  color: rgb(69 150 202);
  line-height: 1.25em;
  font-weight: 500;  
  font-size: 35px;
  padding-left: 10px;
  padding-right: 10px;
}

.do_center_buttons
{
  margin: 0 auto;
  width: 190px;
}


.do_overlay_button
{
  float: left;
}


.do_action_item
{
  float: left;
  margin: 20px;
}


.do_action_item span i
{
  font-size: 50px;
}

.do_action_cancel
{
  color: red;
}


.do_action_confirm
{
  color: lime;
}

