.cloud_printer_item
{
  clear: both;
  height: 115px;  
  border: 1px solid lightgrey;
  border-radius: 0px;
  margin: 10px;
  background-color: #f3f4f5; 
}


.cloud_printer_item_edit
{
  clear: both;
  height: 145px; 
  border: 1px solid lightgrey;
  border-radius: 0px;
  margin: 10px;
  background-color: #f3f4f5; 
}


.cpi_section
{
  float:  left; 
}


.cpi_section_left
{
  border-left: 1px solid lightgrey;
  width: 6%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4px;
}


.cpi_section_right
{
  width: 94%;
  margin-top: 5px;
}


.cpi_actions
{
  float: left;
  margin-left: 5px;
}

.cpi_specs
{
  float: left;
  width: 100%
}

.cpi_info
{
  width: 100%;
  text-align: left;
  margin-top: 3px;
  line-height: 35px;
  height: 27px;   
}


.cpi_edit_printer_info
{
  float: left;
  display: flex;
  width: 50%;
}

.cpi_actions_item
{
  width: 50%;
  float: left;
}


.printerName
{
  font-size: 16px;
  margin-top: 0px;
}


.printerEditName
{
  font-size: 16px;
  margin-bottom: 25px;
}


.printerNick
{
  margin-top: -1px;
  margin-bottom: 3px;
}


.printerEditNick
{

}

.printerCloudToken
{
  float: left;
/*
  background: lightgrey;
  border-radius: 5px;
  width: 72%;
  text-align: center;
*/
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 35px;
}


.printerCloudToken_url
{
  float: left;
  background: lightgrey;
  border-radius: 4px;
  /* padding-left: 8px; */
  width: 72%;
  text-align: center;
}


.printerCloudToken_action
{
  float: left;
  width: 28%;
  padding-left: 10px;
}


.cpi_token_button
{
  font-size: .9rem;
  border-radius: 4px;
  /* font-weight: 350; */
  line-height: 1.0;
  padding: 0px;
  margin: 0px;
  width: 45%;
  height: 20px;
  border: 1px solid lightgrey;
}

.cpi_token_button_regen
{
  font-size: .9rem;
  border-radius: 5px;
  /* font-weight: 350; */
  line-height: 1.0;
  padding: 0px;
  margin: 0px;
  width: 58%;
  height: 35px;
  border: 1px solid lightgrey;
}

.cpi-edit-div
{
  text-align: left;
}


.cpi-label-div
{
  text-align: left;
  font-size: 14px;
}

.cpi-name-label-div
{
  width: 34%;
  min-width: 135px; 
}

.cpi-nick-label-div
{
  width: 17%; 
  min-width: 72px;
}


.cpi-input-div
{
  width: 100%;
}

.cpi-input-div input
{
  border: 2px solid gainsboro;
  border-radius: 4px;
  font-size: 12pt;
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
  height: 30px;
}


.cpi_printer_dropdown
{
  width: 500px;   
}


/* Tooltip text */
.cpi_token_button_regen .tooltiptext 
{
  visibility: hidden;
  width: 310px;
  background-color: #a8c5df;
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-top: -85px; 
  margin-left: -100px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.cpi_token_button_regen:hover .tooltiptext 
{
  visibility: visible;
}
