
.SecurityCodeInput {
  position: relative;
  text-align: left;
  color: grey;
}

.SecurityCodeInput.icons{
  position: relative;
  top: 5px;
  left: 5px;
  margin-right: 10px;

}

.SecurityCodeInput > input {
    /* border: 2px solid gainsboro; */
    /* border-radius: 4px; */
    /* padding: 20px 10px ; */
    line-height: 50px;
    font-size: 12pt;
    text-align: left;
    /* margin: 10px 0; */
}

.SecurityCodeInput_SecurityCodeError > input
{
  border: 2px solid red !important;
} 

