.TextInputBox{
  text-align: left;
  color: grey;
}

.TextInputBox input[type="text"]{
  border: 2px solid gainsboro;
  border-radius: 4px;
  padding: 20px 10px ;
  /* line-height: 50px; */
  font-size: 12pt;
  text-align: left;
  margin: 10px 0;
  width:50%;
} 

