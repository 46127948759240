.PTCanvas
{
  padding-left: 20px;
}

#content 
{
    background-color: #fbfbfb;
    margin-right: 20px;
    padding-bottom: 25px;
    position: relative;
    min-height: 650px;
    width: auto;
    margin-top: -2px;
    border-radius: 0px;
}

.content_menu 
{

    margin-left: 230px;

}

.container-fluid {

    padding-right: 20px;
    padding-left: 20px;
    *zoom: 1;

}