

.email-payment-link{
    margin: 25px;
    border: 1px solid gainsboro;
    /* border: 10px solid yellow;  */
}

.email-payment-link .designer-controls {
    padding: 0px 50px 30px 50px;
}

.email-payment-link input[type="number"]{
    color: #555;
    height: 40px;
    width: 40%;
    font-size: 16px;
    padding-left: 17px;

}

.email-payment-link input[type="text"],
.email-payment-link input[type="url"],
.email-payment-link input[type="email"]{
    color: #555;
    height: 40px;
    width: 40%;
    font-size: 16px;
    padding-left: 12px;
}

.email-payment-link .AmountInputBox input[type="text"] {
    color: #555;
    height: 40px;
    line-height: 40px; 
    width: 100%;
    font-size: 16px;
    padding-left: 20px;
}

.email-payment-link .AmountInputBox i.input-left-icon
{  
  color: #A9A9A9; 
  position: absolute;
  float:left;
  z-index: 2;
  margin-left: 15;
  transform: scale(1.4, 1.4);
  text-align: center;
  font-style: normal;
  top: 20%;
  margin-right: 10px;
}


.email-payment-link .labelDesc
{
  color : #A9A9A9;
}


.email-payment-link-text-area
{
  resize: none;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
}

.email-send-button 
{
  background-color: #f98e38;
  color:white;
  border-radius: 0px;
  float: left;
  min-width: 125px;
}


.email-payment-link input[type="file"] + label {
  border: 1px solid darkgrey;
  background-color: gainsboro;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  min-width: 100px;
}

.email-payment-link input[type="file"]{
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
}

.email-payment-link .errorLabel
{
  color : black;
  font-size: 18px;
}

