.payment-form {
    /* border: 3px solid yellow; */
    /* width: 100%; */ /* no longer does anything */
}

.payment-form {
    /* white-space: nowrap; */ /* does not do anything */
    /* margin: 0 auto; */ /* unnecessary */
    /* max-width: 1435px; */ /* need to check of still needed on a wider screen */
    /* height: 100%; */ /* no longer does anything */
    /* width: 100%; */ /* no longer does anything */
    background-color: #DEE2E6;
    color: #B2B7C3;
    /* setting this section to a known alignment state so independent of parent */
    text-align: left;
    /* display: inline-block; */
}

.nav-buttons .previous-button {
    /* margin-right: 50%; */
}

.payment-form .paymentform-body {
    /* border: 2px dotted purple; */
    /* width: 100%; */
}

.payment-form .nav-buttons {
    width: 100%;
}

.nav-buttons .float-right-in-row {
    margin-left: auto;
}

button.flat {
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: none;
    border-radius: 5px;
}

button:disabled {
    background-color: gray !important;
    opacity: 0.3 !important;
    cursor: not-allowed;
}

/* Inputbox placeholder text styling */
::-webkit-input-placeholder{ /* Chrome/Opera/Safari */
  font-style: italic;
  color: #D3D3D3;
}

::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
  color: #D3D3D3;
}

:-ms-input-placeholder { /* IE 10+ */
  font-style: italic;
  color: #D3D3D3;
}

:-moz-placeholder { /* Firefox 18- */
   font-style: italic;
   color: #D3D3D3;
}

.company-info {
    /* font-family: Helvetica_Neue; */
    /* display: inline-block; */ /* no longer needed */
    margin-top: -75px;
    color: #7C7F84;
    /* Added this because was aligning in the middle vertically */
    /* vertical-align: top; */ /* no longer needed */
    /* margin-left: 194px;
    margin-right: 70px; */
}

.image-circle-frame {
    display: inline-block;
    background-position: center;
    background-size: 125%;
    background-color: white;
    position: relative;
    overflow: hidden;
    /*border-radius: 50%;*/
}
