#pt_header 
{

    height: 90px;
    background-color: #fff;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    z-index: 20;
}


#pt_header h1 {

    left: 0px;
    top: 20px;
    overflow: hidden;
    position: relative;

}


h1 {

    font-size: 38.5px;

}

h1, h2, h3 {

    line-height: 40px;

}


h1, h2, h3, h4, h5, h6 {

    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 20px;
    color: inherit;
    text-rendering: optimizelegibility;

}


.headerGroup
{
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}