.DatePicker span {
    vertical-align: -30%;
}
.DatePicker span.calendar-icon {
    pointer-events:none;
    position: relative;
    /* background-color: red; */
    float: right;
    height: 30px;
    padding-left: 40px;
    margin-top: -37px;
}

.DatePicker img {
    margin-left: -20px;
}
.DayPickerPanel {
    background-color: gainsboro;
    border: 2px solid gray;
}

.date-input-error input{
    border: 2px solid red;
}
