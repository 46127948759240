.CreditCard input[type="text"] {
    /* line-height: 48px; */
}

.CreditCard .credit-card-icon {
    /* border: 2px solid green; */
    /* position: inline-block; */
    height: 40px;
    /* aligning hack with min width of input cc box */
    /* max-width: 400px; */
    text-align: right;
    margin-top: -40px;
    margin-bottom: 8px;
}

.CreditCard label {
    display: block;
}
