.paymentform-form {
    /* border: 2px solid red; */
}

.paymentform-form {
    width: 100%;
    display: inline-block;
    /* white-space: normal; */
    background-color: #f8f9fa;
    margin-top: -150px;
    padding-bottom: 60px;
    /* margin-right: 200px; */ /* does not do anything - delete later */
    /* width: 750px; */
    /* filter: drop-shadow(0 0 5px gainsboro); */
    box-shadow: 0px 0px 15px 0px #777777;
}

.paymentform-form .SectionText .content {
    /* doesn't do anything - will delete later */
    /* max-width: 350px; */
    /* white-space: pre-wrap; */
}

.paymentform-form>div {
    padding: 0px 30px 0px 70px;
}

.form-heading {
    /* doesn't do anything anymore delete later */
    /* display:inline-block; */
    /* margin-left: -30px; */
}

.paymentform-form .total-section {
    border-bottom: 1px solid gainsboro;
    border-top: 1px solid gainsboro;
    margin-bottom: 30px;
    margin-top: 50px;
}

.paymentform-form
i.input-left-icon
{
  color: #555; 
  position: absolute;
  display: block;
  top: 50%;
  transform: scale(1.5, 1.5);
  pointer-events: none;
  width: 20px;
  text-align: center;
  font-style: normal;
}

.paymentform-form .nav-buttons button {
    color: #FFF;
    background: #B2B7C3;
}

.paymentform-form .nav-buttons button.back {
    color: #000000;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.paymentform-form ul.horizontal {
    list-style: none;
    margin: 0;
    padding: 0;
}
.paymentform-form ul.horizontal li {
    float: left;
}

.create-account {
    text-align: right;
}

.create-account button {
    width: 200px;
    height: 50px;
}
