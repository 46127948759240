.TotalPaymentBox{
  position: relative;
  color: grey;
  /* background-color: white; */
  margin: 10px 0;
}

.TotalPaymentBox > input {
    background-color: transparent;
    border: 0px;
    padding: 3px 10px ;
    line-height: 40px;
    font-size: 14pt;
    margin: 4px 0;
    outline: none;
    box-shadow: none;
}

