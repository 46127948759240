.steps {
    /* border: 2px solid blue; */
    width: 400px;
}

.payment-fields {
    /* border: 2px dotted pink; */
}

.billing-fields {
    /* border: 2px solid blue; */
    /* width: 430px; */
    /* padding-left: 60px; */
    color: #3B424E;
}
.billing-fields select {
    height: 50px;
    width: 100%;
    font-size: 20px;
    margin-bottom: 15px;
}

.billing-fields .zipCodeInput {
    padding-top: 20px;
}

.billing-fields input {
    margin-bottom: 15px;
}

.billing-fields .side-by-side div.control {
    display: inline-block;
    padding-bottom: 50px;
    width: 50%;
}

.billing-fields .side-by-side div.control:first-child {
    padding-right: 5px;
}

.billing-fields .control .sub-text {
    display: block;
    color: #7C7F84;
    font-size: 12px;
    max-width: 300px;
}
