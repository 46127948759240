.form-custom-button { /* CSS DEBUG */
    /* background-color: lightgray; */
}

.form-custom-button {
    display: inline-block;
    width: 100%;
    height: 150px;
    padding-bottom: 25px;
    padding-left: 5px;
    border: 1px solid gainsboro;
}


/* crazy positioning here and some inline */
/* some reason right after drawing svg unable */
/* to manage the style in this csv file  */
.form-custom-button {
    text-align: center;
}
.circle-thing {
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;
}

.form-custom-button input[type="radio"]:focus {
    outline: none;
}

.form-custom-button input[type='radio'] {
    border: 1px solid lightgray;
    background-color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    /* display: inline-block; */
    /* position: relative; */
    /* remove native browser platform styling for the radio input */
    -webkit-appearance: none;
       -moz-appearance: none;
        -ms-appearance: none;
         -o-appearance: none;
            appearance: none;
}

/* put a blank space with unchecked style */
/* and a little smaller after radio button */
.form-custom-button input[type='radio']:after {
    background-color: #FFFFFF;
    border-radius: 5px;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    margin: 4px;
    /* left: 23%; */
    /* top: 23%; */
    /* position: relative; */
}

.form-custom-button input[type="radio"]:checked:after {
    background-color: #4696CA;
}
