.paymentform-form {
    /* 
     Adjusting the UI for the sample. Because sample smaller that
     real thing
    */
    margin-right: 10px;
}

.payment-form-designer .sample { /* CSS DEBUG */
    /* border: 10px solid yellow; */
}

.payment-form-designer {
    margin: 25px;
    border: 1px solid gainsboro;
}

/* Preview and Save Buttons */
.designer-controls > div:first-child button {
    margin: 20px;
}

.payment-form-designer .designer-controls {
    padding: 0px 50px 30px 50px;
}

.payment-form-designer button.btn {
    min-width: 150px;
}

.payment-form-designer button.btn.pt-primary {
    background-color: #F98E38;
    border-radius: 0px;
}

button>.small-icon {
    vertical-align: -1px;
    margin-right: 5px;
}

.small-icon {
    height: 14px;
    width: 14px;
}


/* note styling the date picker look at the react date picker component */
/* copied from comus payment link css since it is not shared. */
.payment-form-designer .sample-form input[type=password],
.payment-form-designer .sample-form input[type="number"],
.payment-form-designer .sample-form input[type="text"],
.payment-form-designer .sample-form input[type="tel"],
.payment-form-designer .sample-form input[type="email"] {
    color: #555;
    height: 45px;
    line-height: 50px;
    width: 100%;
    font-size: 20px;
}

.payment-form-designer .sample-form
{
    pointer-events: none;
}

.payment-form-designer input#amount {
    /* since amount box is shared making border important so doesn't ever have that error red border */
    border: 1px solid #CCC !important; 
}
