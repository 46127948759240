.InputBoxCustom input[type=text] {
  font-size: 15px;
  border-radius: 3px 0px 0px 3px;
  -webkit-border-radius: 3px 0px 0px 3px;
  -moz-border-radius: 3px 0px 0px 3px;
  height: 34px;
  width: 100px;
  border: 1px solid lightgray;
  /* background-color: green; */
}

.InputBoxCustom button {
  height: 34px;
  width: 34px;
  border: 1px solid lightgray;
  cursor: pointer;
  /* background-image: url("") */
  vertical-align: top;
  /* text-align: center; */
}

.InputBoxCustom button>.innerButtonColorSwatchSquareThing {
    background-color: green;
    height: 18px;
    width: 18px;
}
